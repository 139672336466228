<template>
  <div>
    <a-row :gutter="[16, 16]" class="mt-4">
      <a-col :span="6">
        <card-counter
          typeCard="primary"
          title="Jumlah Murid"
          :valueCard="dataPersonal.count_all_murid"
        />
      </a-col>
      <a-col :span="6">
        <div @click.prevent="toDetail('jenis_kelamin', 'Laki-Laki')">
          <card-counter
            typeCard="warning"
            title="Murid Laki-Laki"
            :valueCard="dataPersonal.count_all_murid_laki_laki"
            isDetail
            :filterField="{ jenis_kelamin: 'Laki-Laki' }"
          />
        </div>
      </a-col>
      <a-col :span="6">
        <div @click.prevent="toDetail('jenis_kelamin', 'Perempuan')">
          <card-counter
            typeCard="info"
            title="Murid Perempuan"
            :valueCard="dataPersonal.count_all_murid_perempuan"
            isDetail
            :filterField="{ jenis_kelamin: 'Perempuan' }"
          />
        </div>
      </a-col>
      <a-col :span="6">
        <div @click.prevent="toDetail('berkebutuhan_khusus', true)">
          <card-counter
            @click.prevent="toDetail('berkebutuhan_khusus', true)"
            typeCard="danger"
            title="Berkebutuhan Khusus"
            :valueCard="dataPersonal.count_all_murid_berkebutuhan_khusus"
            isDetail
            :filterField="{ berkebutuhan_khusus: true }"
          />
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col :span="16">
        <div class="border rounded p-3">
          <h4 class="mb-4">Agama & Kepercayaan</h4>
          <bar-chart
            :labels="mappedAgama"
            :data="dataPersonal.data_chart_agama"
            :options="agamaDataSet.options"
          />
        </div>
      </a-col>
      <a-col :span="8">
        <div class="border rounded p-3">
          <h4 class="mb-4">Kewarganegaraan</h4>
          <doughnut-chart
            :labels="kewarganegaraanDataSet.labels"
            :data="dataPersonal.data_chart_kewarganegaraan"
            :options="optionsKewarganegaraan"
          />
        </div>
        <div class="border rounded p-3 mt-3">
          <h4 class="mb-3">
            Jumlah Registrasi PPDB <br />
            Yang Masih Pending
          </h4>
          <p>
            <span class="font-weight-bold">{{
              dataPersonal.count_all_murid_pending_ppdb
            }}</span>
            Murid
          </p>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { religions, transportationModeList } from '@/helpers/listsPpdbSelect'
const kewarganegaraanDataSet = {
  labels: ['WNI', 'WNA'],
  data: [77, 13],
}
const agamaDataSet = {
  // data: [
  //   { y: 'Islam', x: 1500 },
  //   { y: 'Purchases', x: 500 },
  //   { y: 'Purchases2', x: 500 },
  //   { y: 'Purchases3', x: 500 },
  //   { y: 'Purchases4', x: 500 },
  // ],
  data: [30, 13, 17, 4, 5, 2, 5, 1],
  options: {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  },
}
const CardCounter = () => import('@/components/app/shared/CardCounter')
const BarChart = () => import('@/components/app/shared/ChartJS/Bar')
const DoughnutChart = () => import('@/components/app/shared/ChartJS/Doughnut')
export default {
  components: {
    CardCounter,
    BarChart,
    DoughnutChart,
  },
  props: {
    dataPersonal: {
      type: Object,
      required: true,
    },
    tahunMasuk: {
      type: String,
    },
  },
  data() {
    return {
      kewarganegaraanDataSet,
      religions,
      transportationModeList,
    }
  },
  methods: {
    handleMenuClick(e) {
      this.schoolYearActive = e.key
    },
    formatValue(value) {
      return `${Number(value).toFixed(0)}`
    },
    toDetail(key, value) {
      this.$router.push({ name: 'Detail Student Candidate Admin', query: { [key]: value, tahun_masuk: this.tahunMasuk } })
    },
  },
  computed: {
    mappedAgama() {
      return this.religions.map(religion => religion.title)
    },
    agamaDataSet() {
      return {
        ...agamaDataSet,
        options: {
          maintainAspectRatio: false,
          onClick: (e, arr) => {
            const index = arr[0]?._index
            console.log(index)
            if (!isNaN(index)) {
              const religionTitle = religions[index].title
              this.$router.push({ name: 'Detail Student Candidate Admin', query: { agama: religionTitle, tahun_masuk: this.tahunMasuk } })
            }
          },
        },
      }
    },
    optionsKewarganegaraan() {
      return {
        onClick: (e, arr) => {
          const index = arr[0]?._index
          if (!isNaN(index)) {
            console.log('masuk')
            if (index === 0) this.$router.push({ name: 'Detail Student Candidate Admin', query: { kewarganegaraan: 'WNI', tahun_masuk: this.tahunMasuk } })
            else if (index === 1) this.$router.push({ name: 'Detail Student Candidate Admin', query: { kewarganegaraan: 'WNA', tahun_masuk: this.tahunMasuk } })
          }
        },
      }
    },
  },
}
</script>

<style>
</style>
